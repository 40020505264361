import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LandingPage from './pages/LandingPage.js';
import Blog from './pages/Blog.js';
import NotFound from './pages/NotFound.js';
import MentionLegal from './pages/MentionLegal.js';
import PrivacyPolicy from './pages/Privacy.js';
import Navbar from "./components/Navbar.js";
import "./App.css";
import CookieBanner from "./components/CookieBanner.js";
import Cookies from "js-cookie";
import CookieModal from "./components/CookieBanner.js";

function App() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // L'effet de défilement fluide
    });
  };
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const userConsent = Cookies.get('userConsent');
    if (!userConsent) {
      setShowModal(true);
    }
  }, []);
  const openCookieModal = () => {
    setShowModal(true); // Ouvrir la modal pour modifier les préférences
  };
  return (
      <Router>
        <Navbar /> {/* Inclure le composant Navbar */}
        <div className="app-container">
          <main className="content">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/mentionlegal" element={<MentionLegal />} />
              {/*<Route path="/blog" element={<Blog />} />*/}
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>

          <footer className="footer">
            <p className="text-color">&copy; 2024 Game Studio</p>
            <nav>
              <div className="div-footer">
                <div className="text-link" onClick={scrollToTop}>Back to top</div>
                <div>
                  <Link className="text-link" to="/">Home</Link>
                </div>
                <div>
                  <Link className="text-link" to="/mentionlegal">Terms of use</Link>
                </div>
                <div>
                  <Link className="text-link" to="/privacy">Privacy</Link>
                </div>

              </div>
            </nav>
            <div>
              <div className="text-link-cookie" onClick={openCookieModal}>Modify Cookies</div>
            </div>
            <div>
              {showModal && <CookieModal setShowModal={setShowModal}/>}
            </div>
          </footer>
        </div>
      </Router>
  );
}

export default App;
