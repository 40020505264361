import React, { useEffect } from 'react';
import './MentionLegal.css';

const MentionLegal = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll vers le haut
    }, []); // Le tableau vide signifie que cela ne se produit qu'une fois, au montage du composant

    return (
        <section className="mentions-section">
            <div className="mentions-list">
                <div className="mention-legales-title">
                    <h1>Terms of use</h1>
                </div>
                {/* Add here cards or elements for each game */}
                <div className="mentions-legales">
                    <h2>Company Information</h2>
                    <p>
                        The website <a href="https://www.rising-out.com">www.rising-out.com</a> (hereinafter referred to as the
                        "Website") is owned by the company <strong>Rising Out SASU</strong>, a simplified joint-stock company
                        with a single shareholder, with a share capital of 500 euros, registered with the Trade and Companies
                        Register of Toulon under number <strong>932 301 179</strong>, with its registered office located at
                        <strong>261 Rue Jean Moulin, 83270 Saint-Cyr-sur-Mer, France</strong> (hereinafter "Rising Out").
                    </p>
                    <p>
                        <strong>Email address</strong>: <a href="mailto:contact@rising-out.com">contact@rising-out.com</a>
                    </p>
                    <p>
                        <strong>Tax Identification Number</strong>: 93230117900012
                    </p>
                    <p>
                        <strong>VAT Number</strong>: FR23932301179
                    </p>

                    <h2>Publication Manager</h2>
                    <p>
                        The publication manager of the Website is <strong>Mr. Boris Roussel</strong>.
                    </p>

                    <h2>Website Hosting</h2>
                    <p>
                        The website is hosted by <strong>OVH</strong>, a simplified joint-stock company with a share capital of
                        10,174,560 euros, with its registered office located at <strong>2 rue Kellermann, 59100 Roubaix,
                            France</strong>.
                    </p>
                    <p>
                        <strong>SIRET</strong>: 424 761 419 00045
                    </p>
                    <p>
                        <strong>VAT Number</strong>: FR22424761419
                    </p>
                    <p>
                        <strong>Phone</strong>: <a href="tel:+33972101007">+33 (0) 9 72 10 10 07</a>
                    </p>
                    <p>
                        <strong>Website</strong>: <a href="https://www.ovh.com" target="_blank" rel="noopener noreferrer">www.ovh.com</a>
                    </p>

                    <h2>Terms of Use</h2>
                    <p>
                        For optimal browsing on the website www.rising-out.com, we recommend using modern browsers such as
                        Safari, Firefox, Google Chrome, or any other up-to-date browser. Rising Out SASU strives to implement
                        all available means to ensure the reliability and accuracy of the information published on its website.
                        However, errors or omissions may occur. Users are therefore advised to verify the accuracy of the
                        information by contacting Boris Roussel at the following address: contact@rising-out.com, and to report
                        any changes deemed necessary. Rising Out disclaims all liability for the use of the information provided
                        on its website, as well as for any direct or indirect damages that may result.
                    </p>
                </div>
            </div>
        </section>
    );

};

export default MentionLegal;