import React, { useEffect } from 'react';
import './LandingPage.css'; // Assure-toi de créer ce fichier CSS
import { motion } from 'framer-motion';

function LandingPage() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll vers le haut
    }, []); // Le tableau vide signifie que cela ne se produit qu'une fois, au montage du composant


    return (
        <div className="landing-page">
            {/* Section de démarrage */}
            <section className="hero-section">
                <h1 className="text-color">Discover Unique and Exciting Games from Our Game Studio</h1>
                <h3 className="text-color">Dive into immersive adventures with our carefully crafted games designed for fun and excitement.</h3>
                <img src="/images/viking-run.png" alt="Hero" className="hero-image" />
            </section>

            <section className="games-section">
                <h1 className="text-color">Our team</h1>
                <div className="team-container">
                    <motion.div className="team-image-container" whileHover={{ scale: 1.1 }}>
                        <img src="/images/boris-roussel.png" alt="Boris Roussel" className="team-image" />
                    </motion.div>
                    <div className="team-info">
                        <h4 className="text-color">
                            At Rising Out, we are passionate about creating unique gaming experiences that bring joy and challenges to players around the world.
                        </h4>
                        <h4 className="text-color">
                            With six years of experience in the video game industry, whether in casual or hyper-casual genres, and across platforms like mobile and PC.
                        </h4>
                    </div>
                </div>
            </section>

            {/* Section pour les jeux */}
            <section className="games-section">
                <h1 className="text-color">Our Games</h1>
                <div className="games-list">

                    {/* Game 1 */}
                    <div className="game-container">
                        <motion.div whileHover={{ scale: 1.0 }} className="game-item">
                            <div className="game-card">
                                <img src="/images/viking-escapes.png" alt="Sandrift" className="game-image" />
                                <div className="game-info">
                                    <h3 className="game-title">Sandrift</h3>
                                    <h5 className="game-description">Join a Viking's daring escape as he slides his sword to break free from the castle!</h5>
                                    {/* Optional video */}
                                </div>
                            </div>
                        </motion.div>
                        <video className="game-video" autoPlay="true" loop="true" muted="true">
                            <source src="/videos/Trailer-release.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                    </div>


                    {/* Game 2 */}
                    <div className="game-container">
                        <motion.div whileHover={{ scale: 1 }} className="game-item">
                            <div className="game-card">
                                <img src="/images/loppy-fish.png" alt="Loppy Fish" className="game-image" />
                                <div className="game-info">
                                    <h3 className="game-title">Loppy Fish</h3>
                                    <h5 className="game-description">An underwater adventure where agility is key!</h5>
                                </div>
                            </div>
                        </motion.div>
                        <video className="game-video" autoPlay="true" loop="true" muted="true">
                            <source src="/videos/Movie_001.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
            </section>

            {/* Section pour les statistiques */}
            {/*<section className="stats-section">
                <h2>Statistiques de l'application</h2>
                <div className="stats">
                    <div className="stat-item">
                        <h3>Nombre de téléchargements</h3>
                        <p>1,000,000+</p>
                    </div>
                    <div className="stat-item">
                        <h3>Nombre de notes</h3>
                        <p>50,000+</p>
                    </div>
                </div>
            </section>*/}
        </div>
    );
}

export default LandingPage;
